import React from "react";
import {Button, Confirm, Tab} from "semantic-ui-react";
import Utils, {ConfirmAction, initial_confirm,} from "../../../utils/Utils";
import {Loading, PDFReader} from "../../../utils/Components"
import {getStaff, Staff} from "../../../store/slices/userSlice"
import {useAppSelector} from "../../../store/hooks"
import {Req} from "../../../utils/Req";
import {ToastsStore} from "react-toasts";
import LoanCollateral, {Security} from "./info/LoanCollateral";
import {intervals, LoanType, rate_types} from "../../admin/loans/LoanTypes";
import {Office} from "../../admin/users/Offices";
import MakePayment, {PaymentData} from "../MakePayment";
import LoanCalculator, {CalculatedSchedule} from "./info/calculator/LoanCalculator";
import LoanStructure from "./info/structure/LoanStructure";
import {ClientInfo, Guarantor, initial_client_info, initial_guarantor, LoanData} from "./info/LoanData";
import {PaymentScheduleIdeal, Schedule} from "./info/schedule/PaymentScheduleIdeal";
import {ActualSchedule, PaymentScheduleActual} from "./info/schedule/PaymentScheduleActual";
import LoanPayments, {LoanPayment} from "./info/payment/LoanPayments";
import OtherTransactions from "./info/OtherTransactions";
import {Extension} from "./info/transactions/LoanExtension";
import {Rights} from "../../admin/settings/AccessRights";
import {Penalty} from "./info/transactions/LoanPenalties"
import {DeductInterest} from "./info/transactions/RemoveInterest";
import {LoanReschedule} from "./info/transactions/LoanReschedules";
import {SystemClient} from "../../clients/CreateClient";
import DisburseLoan from "./utils/DisburseLoan";
import DeleteLoan from "./utils/DeleteLoan";
import {Account} from "../../../utils/Models";
import BadLoan from "./utils/BadLoan";
import DummySchedule from "./utils/DummySchedule";

export interface LoanCharge {
    fees: Array<{ amount_id: number, min_value: number, charge_value: number, charge_type: 'Flat' | 'Percentage' }>
    charge_id: number
    type_id: number
    fee_name: string
}

export interface LoanProps {
    loanTypes: Array<LoanType>
    offices: Array<Office>
    userInfo: Staff
    charges: Array<LoanCharge>
    clients: Array<SystemClient>
    staffs: Array<{ user_id: number, user_name: string, user_status: "inactive" | 'active' }>
}

export type loan_statuses = 'pending' | 'rejected' | 'accepted' | 'verified' | 'active' | 'deleted' | 'cleared'

interface LoanStatus {
    status_id: number
    user_id: number
    status_name: 'created' | 'modified' | 'rejected' | 'accepted' | 'verified' | 'active'
    status_date: string
    user_name: string
}

export interface LoanFee {
    cashier_name: string
    charge_amount: number | string
    charge_type: "Percentage" | 'Flat'
    charge_value: number
    date_paid: string
    charge_id: number
    fee_name: string
}

export interface LoanSecurity {
    security_desc: string
    security_id: number
    security_images: string[]
    security_name: string
    security_value: number
}

export interface LoanInfo {
    loan_id: number
    office_id: number

    inspector_id: number
    loan_type: LoanType | null
    charges: LoanFee[]
    rate_type: rate_types
    payment_interval: intervals
    loan_principal: string
    loan_rate: string
    loan_period: string
    projected_interest: string
    start_date: string
    due_date: string

    client_id: number
    client_info: ClientInfo
    guarantors: Guarantor[]
    broker: { broker_name: string, broker_contact: string, broker_commission: string }
    securities: LoanSecurity[]

    schedules: {
        expected: Array<Schedule>
        actual: Array<ActualSchedule>
    }

    loan_status: loan_statuses
    payments: {
        data: Array<LoanPayment>
        next: { date: string, amount: number }
        summary: {
            principal: { total: number, paid: number, balance: number }
            interest: { total: number, paid: number, balance: number }
            penalties?: { total: number, paid: number, balance: number }
            charges?: { total: number, paid: number, balance: number }
        }
    }

    others: {
        extension: { data: Extension[], proceed: 1 | 0 }
        penalties: Penalty[]
        removals: DeductInterest[]
        reschedule: { data: LoanReschedule[], date: string }
        loan_statuses: LoanStatus[]
        journals: {
            credit: number
            debit: number
            entries: { date: string, items: { dr: number, cr: number, ac: string, et: string, id: number }[] }[]
        }
    }
}

export const initial_loan_info: LoanInfo = {
    client_id: 0, client_info: initial_client_info, loan_status: 'pending', loan_id: 0, loan_period: "", loan_principal: "",
    due_date: "", loan_rate: "", loan_type: null, projected_interest: "", guarantors: [initial_guarantor, initial_guarantor],
    rate_type: '', start_date: Utils.today(), payment_interval: 30, charges: [],
    inspector_id: 0, broker: {broker_name: "", broker_contact: "", broker_commission: ""},
    office_id: 0, securities: [],
    others: {
        extension: {data: [], proceed: 0}, journals: {credit: 0, debit: 0, entries: []},
        penalties: [], removals: [], reschedule: {data: [], date: ''}, loan_statuses: []
    },
    schedules: {expected: [], actual: []},
    payments: {
        data: [], next: {date: '', amount: 0}, summary: {
            principal: {total: 0, balance: 0, paid: 0},
            interest: {total: 0, balance: 0, paid: 0}
        }
    },
}

export function ShowLoan(props: { close?: (loanInfo?: LoanInfo) => void, loan_id: number, accounts: Account[] }) {
    const user = useAppSelector(getStaff)

    const [loading, setLoading] = React.useState({message: "", show: false})
    const [editDisabled, setEditDisabled] = React.useState(false)

    const [securities, setSecurities] = React.useState<Array<Security>>([])
    const [confirm, setConfirm] = React.useState<ConfirmAction>(initial_confirm)

    const [calculate, setCalculate] = React.useState(false)
    const [showReader, setShowReader] = React.useState({display: false, source: ""})
    const [makePayment, setMakePayment] = React.useState<{ show: boolean, loan: PaymentData | undefined }>({show: false, loan: undefined})
    const [showDisburse, setShowDisburse] = React.useState(false)
    const [showDelete, setShowDelete] = React.useState(false)
    const [showBadDebt, setShowBadDebt] = React.useState(false)
    const [showPrintProvisional, setShowPrintProvisional] = React.useState(false)

    /*LOAN PROPS*/
    const [loanInfo, setLoanInfo] = React.useState<LoanInfo>(initial_loan_info)
    const [loanProps, setLoanProps] = React.useState<LoanProps>({
        loanTypes: [], offices: [], charges: [], userInfo: user.staff, clients: [], staffs: []
    })

    const save_loan = () => {
        const principal = Utils.strip_commas(loanInfo.loan_principal)

        if (loanInfo.inspector_id === 0) {
            ToastsStore.error("Select a loan inspector")
        } else if (!Utils.is_valid_number(principal) || parseFloat(principal) < 50000) {
            ToastsStore.error("Enter valid loan principal above ugx 50,000")
        } else if (loanInfo.loan_type === null) {
            ToastsStore.error("Select a loan type")
        } else if (loanInfo.rate_type === "") {
            ToastsStore.error("Select a loan rate type")
        } else if (!Utils.is_valid_number(loanInfo.loan_rate) || parseFloat(loanInfo.loan_rate) < 0 || parseFloat(loanInfo.loan_rate) > 100) {
            ToastsStore.error("Enter a valid loan rate")
        } else if (!Utils.is_valid_number(loanInfo.loan_period) || parseInt(loanInfo.loan_period) < 1) {
            ToastsStore.error("Enter a valid loan period")
        } else if (loanInfo.schedules.expected.length === 0) {
            ToastsStore.error("You have not calculated the loan schedule yet, " +
                "Please first revise the loan schedule by clicking on the Expected Schedule tab")
        } else if (loanInfo.client_id < 1) {
            ToastsStore.error("Select a client")
        } else if (loanInfo.guarantors[0].client_id > 0 && loanInfo.guarantors[0].relationship === "") {
            ToastsStore.error("Select relationship with first guarantor")
        } else if (loanInfo.guarantors[0].client_id > 0 && (!Utils.is_valid_number(loanInfo.guarantors[0].monthly_income)
            || parseFloat(loanInfo.guarantors[0].monthly_income) < 0)) {
            ToastsStore.error("Enter valid monthly income for first guarantor")
        } else if (loanInfo.guarantors[1].client_id > 0 && loanInfo.guarantors[1].relationship === "") {
            ToastsStore.error("Select relationship with second guarantor")
        } else if (loanInfo.guarantors[1].client_id > 0 && (!Utils.is_valid_number(loanInfo.guarantors[1].monthly_income)
            || parseFloat(loanInfo.guarantors[1].monthly_income) < 0)) {
            ToastsStore.error("Enter valid monthly income for second guarantor")
        } else {
            let has_error = false;
            let total_charges = 0
            const charges = loanInfo.charges
            for (let index = 0; index < charges.length && !has_error; index++) {
                if (!Utils.is_valid_number(charges[index].charge_amount.toString()) || parseFloat(charges[index].charge_amount.toString()) < 0) {
                    ToastsStore.error(`Enter a valid charge for ${charges[index].fee_name}`)
                    has_error = true
                } else {
                    total_charges += parseFloat(Utils.strip_commas(charges[index].charge_amount))
                }
            }

            if (loanInfo.broker.broker_commission.trim() !== '' || loanInfo.broker.broker_name.trim() !== '' ||
                !Utils.invalid_contact(loanInfo.broker.broker_contact)) {
                if (Utils.invalid_full_name(loanInfo.broker.broker_name)) {
                    ToastsStore.error("Please insert a valid broker name")
                    has_error = true
                } else if (Utils.invalid_contact(loanInfo.broker.broker_contact)) {
                    ToastsStore.error("Please insert a valid broker contact")
                    has_error = true
                } else if (!Utils.is_valid_number(loanInfo.broker.broker_commission.trim()) || parseFloat(loanInfo.broker.broker_commission.trim()) < 0) {
                    ToastsStore.error("Please insert a valid broker commission")
                    has_error = true
                }
            }


            if (has_error) {
                return
            } else if (total_charges >= parseFloat(principal)) {
                ToastsStore.error("Total charges cannot be more than loan principal")
                return
            }

            const form_data = new FormData()
            form_data.append("loan_id", loanInfo.loan_id.toString())
            form_data.append("loan_principal", principal)
            form_data.append("loan_type", loanInfo.loan_type!!.type_id.toString())
            form_data.append("client_id", loanInfo.client_id.toString())
            form_data.append("rate_type", loanInfo.rate_type)
            form_data.append("payment_interval", loanInfo.payment_interval.toString())
            form_data.append("loan_rate", loanInfo.loan_rate)
            form_data.append("loan_period", loanInfo.loan_period)
            form_data.append("projected_interest", loanInfo.projected_interest)
            form_data.append("start_date", loanInfo.start_date)
            form_data.append("due_date", loanInfo.due_date)
            form_data.append("inspector_id", loanInfo.inspector_id.toString())
            form_data.append("charges", JSON.stringify(
                charges.map((charge) => ({
                    charge_id: charge.charge_id,
                    charge_type: charge.charge_type,
                    charge_value: charge.charge_value,
                    charge_amount: parseFloat(charge.charge_amount.toString())
                }))
            ))
            form_data.append("broker", JSON.stringify(loanInfo.broker))
            form_data.append("guarantors", JSON.stringify(loanInfo.guarantors))

            const loan_securities: Array<any> = []
            securities.forEach((security, index) => {
                loan_securities.push({
                    name: security.collateral_name,
                    desc: security.collateral_description,
                    value: security.collateral_value,
                    id: security.collateral_id
                })

                security.images.forEach((image) => {
                    if (image.file !== undefined) {
                        form_data.append(`securities${index + 1}[]`, image.file)
                    }
                })
            })
            form_data.append("securities", JSON.stringify(loan_securities))

            setConfirm({
                ...confirm, open: true, content: 'Are you sure you want to proceed with saving this loan information?',
                onConfirm: () => {
                    setConfirm({...confirm, open: false})
                    setLoading({message: "Saving loan information, please wait", show: true})
                    Req.save_loan(form_data)
                        .then(response => {
                            setLoading({message: "", show: false})
                            if (response.data.hasOwnProperty("code")) {
                                switch (response.data.code) {
                                    case 1:
                                        setLoanInfo(initial_loan_info)
                                        ToastsStore.success("Loan has been saved successfully")
                                        break;
                                    case 2:
                                        ToastsStore.info("Loan was already disbursed, it cannot be modified")
                                        break
                                    default:
                                        ToastsStore.error("Error while saving loan, please retry")
                                        break;
                                }
                            } else {
                                ToastsStore.error("Error while saving loan, please retry")
                            }
                        })
                        .catch(() => {
                            setLoading({message: "", show: false})
                            ToastsStore.error("Error while saving loan, please retry")
                        })
                },
                onCancel: () => setConfirm({...confirm, open: false})
            })
        }
    }

    const approve_reject_loan = (loan_status: 'rejected' | 'accepted' | 'verified') => {
        setLoading({message: "Updating loan status, please wait", show: true})
        Req.approve_reject_verify({loan_status: loan_status, loan_id: loanInfo.loan_id, branch_id: loanInfo.office_id})
            .then(response => {
                setLoading({message: "", show: false})
                if (response.data.hasOwnProperty("code") && response.data['code'] === 1) {
                    setLoanInfo({...loanInfo, loan_status: loan_status})
                    ToastsStore.success("Your loan status action has been successfully accepted")
                } else {
                    ToastsStore.error("Could not update loan status please retry")
                }
            })
            .catch(() => {
                setLoading({message: "", show: false})
                ToastsStore.error("Could not update loan status please retry")
            })
    }

    const print_schedule = () => {
        setLoading({message: "Generating schedule, please wait", show: true})
        Req.get_payment_schedule({loan_id: loanInfo.loan_id})
            .then((response) => {
                if (response.data.hasOwnProperty("file")) {
                    setShowReader({display: true, source: `${Req.BASE_URL}${response.data.file}`})
                } else {
                    ToastsStore.error("Error while generating loan schedule")
                }
                setLoading({...loading, show: false})
            })
            .catch(() => {
                setLoading({...loading, show: false})
                ToastsStore.error("Error while generating loan schedule")
            })
    }

    const print_loan_info = () => {
        setLoading({message: "Generating loan info, please wait", show: true})
        Req.get_payment_info({loan_id: loanInfo.loan_id})
            .then((response) => {
                if (response.data.hasOwnProperty("file")) {
                    setShowReader({display: true, source: `${Req.BASE_URL}${response.data.file}`})
                } else {
                    ToastsStore.error("Error while generating loan info")
                }
                setLoading({...loading, show: false})
            })
            .catch(() => {
                setLoading({...loading, show: false})
                ToastsStore.error("Error while generating loan info")
            })
    }

    const print_provisional_schedule = () => {
        const principal = Utils.strip_commas(loanInfo.loan_principal)

        if (loanInfo.loan_type === null) {
            ToastsStore.error("Select a loan type")
        } else if (!Utils.is_valid_number(principal) || parseFloat(principal) < 50000) {
            ToastsStore.error("Enter valid loan principal above ugx 50,000")
        } else if (loanInfo.rate_type === "") {
            ToastsStore.error("Select a loan rate type")
        } else if (!Utils.is_valid_number(loanInfo.loan_rate) || parseFloat(loanInfo.loan_rate) < 0 || parseFloat(loanInfo.loan_rate) > 100) {
            ToastsStore.error("Enter a valid loan rate")
        } else if (!Utils.is_valid_number(loanInfo.loan_period) || parseInt(loanInfo.loan_period) < 1) {
            ToastsStore.error("Enter a valid loan period")
        } else if (loanInfo.schedules.expected.length === 0) {
            ToastsStore.error(`You have not calculated the loan schedule yet, Please first revise the loan schedule by clicking on the Expected Schedule tab`)
        } else {
            let has_error = false;
            const charges = loanInfo.charges
            for (let index = 0; index < charges.length && !has_error; index++) {
                if (!Utils.is_valid_number(charges[index].charge_amount.toString()) || parseFloat(charges[index].charge_amount.toString()) < 0) {
                    has_error = true
                    ToastsStore.error(`Enter a valid charge for ${charges[index].fee_name}`)
                }
            }

            if (!has_error) {
                setShowPrintProvisional(true)
            }
        }
    }

    const adjust_loan_info = () => {
        setLoading({message: "Adjusting  loan info, please wait", show: true})
        Req.adjust_loan({loan_id: loanInfo.loan_id})
            .then((response) => {
                setLoading({...loading, show: false})
                if (response.data.hasOwnProperty("code")) {
                    if (response.data.code === 1) {
                        ToastsStore.success("Loan data adjusted successfully")
                        setLoanInfo(Utils.format_loan_data(response))
                    } else if (response.data.code === 503) {
                        ToastsStore.info(`This service is not yet available at the moment`)
                    }
                } else {
                    ToastsStore.error("Error while adjusting loan info")
                }
            })
            .catch(() => {
                setLoading({...loading, show: false})
                ToastsStore.error("Error while adjusting loan info")
            })
    }

    const get_payment_info = () => {
        setLoading({message: "Loading payment details, please wait", show: true})
        Req.get_loan_payment_data({loan_id: loanInfo.loan_id, office_id: user.staff.office_id})
            .then((response) => {
                setLoading({message: "", show: false})
                if (response.data.hasOwnProperty('code')) {
                    if (response.data.code === 1) {
                        const loan_info = response.data.info as PaymentData
                        if (loan_info.balance.interest === 0 && loan_info.balance.principal === 0) {
                            ToastsStore.error("Loan was already cleared")
                        } else {
                            setMakePayment({loan: loan_info, show: true})
                        }
                    } else if (response.data.code === 2) {
                        ToastsStore.error("Loan could not be found")
                    }
                }
            })
            .catch(() => {
                setLoading({message: "", show: false})
                ToastsStore.error("Could not load payment data")
            })
    }

    const search_loan = (loan_id: number) => {
        setLoading({message: "Loading loan information, please wait", show: true})
        Req.get_loan_info({loan_id: loan_id})
            .then(response => {
                setLoading({...loading, show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setLoanProps({
                        userInfo: user.staff,
                        charges: response.data.initial.charges,
                        clients: response.data.initial.clients,
                        loanTypes: response.data.initial.types,
                        offices: response.data.initial.branches,
                        staffs: response.data.initial.staffs
                    })
                    if (response.data.hasOwnProperty("loan")) {
                        setLoanInfo(response.data.loan)
                    }
                } else {
                    ToastsStore.error("Error while loading loan data")
                    if (props.close !== undefined) {
                        props.close()
                    }
                }
            })
            .catch(() => {
                setLoading({...loading, show: false})
                ToastsStore.error("Error while loading loan information")
                if (props.close !== undefined) {
                    props.close()
                }
            })
    }

    React.useEffect(() => {
        setSecurities(
            loanInfo.securities.map((collateral, index) =>
                ({
                    index: index,
                    collateral_id: collateral.security_id,
                    collateral_name: collateral.security_name,
                    collateral_value: collateral.security_value.toString(),
                    collateral_description: collateral.security_desc,
                    images: collateral.security_images.map((image) => ({path: `${Req.BASE_URL}${image}`}))
                })
            )
        )
    }, [loanInfo.securities])

    React.useEffect(() => {
        setEditDisabled(!['pending', 'rejected'].includes(loanInfo.loan_status))
    }, [loanInfo.loan_status])

    React.useEffect(() => {
        if (props.loan_id >= 0) {
            search_loan(props.loan_id)
        }
    }, [props.loan_id])

    return (
        <>
            <Loading show={loading.show} text={loading.message} hide={() => setLoading({...loading, show: false})}/>

            {
                makePayment.loan !== undefined &&
                <MakePayment
                    loanInfo={makePayment.loan} show={makePayment.show} accounts={props.accounts}
                    close={(loanInfo, file) => {
                        setMakePayment({loan: undefined, show: false})
                        if (loanInfo !== undefined && file !== undefined) {
                            setLoanInfo(loanInfo)
                            setShowReader({display: true, source: file})
                            ToastsStore.success("Successfully made payment")
                        }
                    }}/>
            }

            <PDFReader source={showReader.source} open={showReader.display}
                       close={() => setShowReader({...showReader, display: false})}/>

            <DummySchedule
                show={showPrintProvisional} loanInfo={loanInfo}
                close={(path) => {
                    if (path !== undefined) {
                        setShowReader({display: true, source: `${Req.BASE_URL}${path}`})
                    } else {
                        setShowPrintProvisional(false)
                    }
                }}/>

            <DisburseLoan
                loanInfo={loanInfo} close={() => setShowDisburse(false)} show={showDisburse}
                success={({loanInfo, path}) => {
                    setLoanInfo(loanInfo)
                    setShowDisburse(false)
                    ToastsStore.success("Loan has been successfully disbursed")
                    setShowReader({display: true, source: path})
                }}/>

            <LoanCalculator
                show={calculate}
                result={(data?: CalculatedSchedule, schedules?: Array<Schedule>, interestRate?: number) => {
                    if (data !== undefined && schedules !== undefined && interestRate !== undefined) {
                        const loan_info: LoanInfo = {
                            ...loanInfo,
                            schedules: {expected: schedules, actual: []},
                            rate_type: data.rate_type, loan_principal: data.principal, loan_period: data.period,
                            start_date: data.start, due_date: data.end, loan_rate: interestRate.toString(),
                            projected_interest: (parseFloat(data.expected) - parseFloat(data.principal)).toString(),
                        }
                        setLoanInfo(loan_info)
                    }
                    setCalculate(false)
                }}/>

            <DeleteLoan
                show={showDelete} loan_id={loanInfo.loan_id}
                close={(deleted) => {
                    if (deleted) {
                        setLoanInfo(initial_loan_info)
                        props.close && props.close({...loanInfo, loan_status: 'deleted'})
                    }
                    setShowDelete(false)
                }}/>

            <BadLoan
                show={showBadDebt} loanInfo={loanInfo}
                close={(aLoanInfo) => {
                    if (aLoanInfo !== undefined) {
                        setLoanInfo(aLoanInfo)
                    }
                    setShowBadDebt(false)
                }}/>

            <Confirm
                open={confirm.open} onCancel={confirm.onCancel} onConfirm={confirm.onConfirm} size='mini'
                cancelButton={confirm.cancelButton} confirmButton={confirm.confirmButton} content={confirm.content}/>

            <Tab menu={{pointing: true,}} className='loan_info_container'
                 panes={[
                     {
                         menuItem: 'Loan Structuring',
                         render() {
                             return (
                                 <Tab.Pane attached={false} className="create_loan_pane" key='loan_structuring'>
                                     <LoanStructure loanProps={loanProps} loanInfo={loanInfo} setLoanInfo={setLoanInfo} editDisabled={editDisabled}/>
                                 </Tab.Pane>
                             )
                         }
                     },
                     {
                         menuItem: 'Client Information',
                         render() {
                             return (
                                 <Tab.Pane attached={false} className="create_loan_pane" key='client_information'>
                                     <LoanData
                                         loanProps={loanProps} loanInfo={loanInfo} setLoanInfo={setLoanInfo} accounts={props.accounts}
                                         setClients={(clients) => setLoanProps({...loanProps, clients: clients})}/>
                                 </Tab.Pane>
                             )
                         }
                     },
                     {
                         menuItem: 'Loan Collateral',
                         render() {
                             return (
                                 <Tab.Pane attached={false} className="create_loan_pane" key='loan_collateral'>
                                     <LoanCollateral
                                         setSecurities={setSecurities} securities={securities} loan_id={loanInfo.loan_id}
                                         update={(aSecurities) => setLoanInfo({...loanInfo, securities: aSecurities})}/>
                                 </Tab.Pane>
                             )
                         }
                     },
                     {
                         menuItem: 'Expected Schedules',
                         render: () => {
                             return (
                                 <Tab.Pane attached={false} className="create_loan_pane" key='expected_schedules'>
                                     <PaymentScheduleIdeal loanInfo={loanInfo} setLoanInfo={setLoanInfo}/>
                                 </Tab.Pane>
                             )
                         }
                     },
                     {
                         menuItem: 'Actual Schedules',
                         render: () => {
                             return (
                                 <Tab.Pane attached={false} className="create_loan_pane" key='actual_schedules'>
                                     <PaymentScheduleActual loanInfo={loanInfo} setLoanInfo={setLoanInfo}/>
                                 </Tab.Pane>
                             )
                         }
                     },
                     {
                         menuItem: 'Loan Payments',
                         render: () => {
                             return (
                                 <Tab.Pane attached={false} className="create_loan_pane" key='loan_payments'>
                                     <LoanPayments loanProps={loanProps} loanInfo={loanInfo} setLoanInfo={setLoanInfo}/>
                                 </Tab.Pane>
                             )
                         }
                     },
                     {
                         menuItem: 'Other Transactions',
                         render: () => {
                             return (
                                 <Tab.Pane attached={false} className="create_loan_pane" key='other_transactions'>
                                     <OtherTransactions loanInfo={loanInfo} setLoanInfo={setLoanInfo}/>
                                 </Tab.Pane>
                             )
                         }
                     },
                 ]}/>

            <div className="form_footer mt-2" style={{textAlign: 'center'}}>
                {
                    props.close !== undefined &&
                    <div className="d-inline-block mx-1">
                        <Button negative size='mini' icon="close" content="Close Window" labelPosition="left"
                                onClick={() => props.close && props.close()}/>
                    </div>
                }
                {
                    props.loan_id > 0 &&
                    <div className="d-inline-block mx-1">
                        <Button primary size='mini' icon="refresh" content={`No: ${props.loan_id}`} labelPosition="left"
                                onClick={() => search_loan(props.loan_id)}/>
                    </div>
                }

                {/*IF NOT YET DISBURSED, ALL MODIFICATIONS ARE POSSIBLE*/}
                {
                    (['pending', 'rejected', 'accepted', 'verified'].includes(loanInfo.loan_status)) &&
                    <>
                        {
                            Rights.has_right(user.roles.user_roles, Rights.create_loan) &&
                            <div className="d-inline-block mx-1">
                                <Button positive size='mini' content='Calculator' labelPosition="left"
                                        icon="calculator" onClick={() => setCalculate(true)}/>

                                <Button
                                    positive size='mini' icon="save" onClick={save_loan} labelPosition="left"
                                    content={loanInfo.loan_id === 0 ? 'Save Loan' : 'Update Loan'}/>
                            </div>
                        }

                        {
                            <div className="d-inline-block mx-1">
                                <Button positive size='mini' content="Provional Schedule" icon="print" onClick={print_provisional_schedule}/>
                            </div>
                        }
                    </>
                }

                {/*SUBMITTED FOR APPROVAL*/}
                {
                    (loanInfo.loan_id > 0 && ['pending', 'rejected'].includes(loanInfo.loan_status) &&
                        Rights.has_right(user.roles.user_roles, Rights.approve_loan)) &&
                    <div className="d-inline-block mx-1">
                        {
                            loanInfo.loan_status !== "rejected" &&
                            <Button
                                negative content="Reject" icon="cancel" size='mini' labelPosition="left"
                                onClick={() => {
                                    setConfirm({
                                        ...confirm, open: true, content: 'Are you sure you want to proceed with rejecting this loan?',
                                        onConfirm: () => {
                                            setConfirm({...confirm, 'open': false})
                                            approve_reject_loan('rejected')
                                        },
                                        onCancel: () => setConfirm({...confirm, 'open': false})
                                    })
                                }}/>
                        }

                        <Button
                            positive content="Approve" icon="check" labelPosition="left" size='mini'
                            onClick={() => {
                                setConfirm({
                                    ...confirm, open: true, content: 'Are you sure you want to proceed with accepting this loan?',
                                    onConfirm: () => {
                                        setConfirm({...confirm, open: false})
                                        approve_reject_loan('accepted')
                                    },
                                    onCancel: () => setConfirm({...confirm, 'open': false})
                                })
                            }}/>
                    </div>
                }

                {/*SUBMITTED FOR VERIFICATION*/}
                {
                    (loanInfo.loan_status === 'accepted' && Rights.has_right(user.roles.user_roles, Rights.verify_loan)) &&
                    <div className="d-inline-block mx-1">
                        <Button
                            negative content="Reject" icon="cancel" size='mini' labelPosition="left"
                            onClick={() => {
                                setConfirm({
                                    ...confirm, open: true, content: 'Are you sure you want to proceed with rejecting this loan?',
                                    onConfirm: () => {
                                        setConfirm({...confirm, open: false})
                                        approve_reject_loan('rejected')
                                    },
                                    onCancel: () => setConfirm({...confirm, open: false})
                                })
                            }}/>

                        <Button
                            positive content="Verify" icon="check" labelPosition="left" size='mini'
                            onClick={() => {
                                setConfirm({
                                    ...confirm, open: true, content: 'Are you sure you want to proceed with verifying this loan?',
                                    onConfirm: () => {
                                        setConfirm({...confirm, open: false})
                                        approve_reject_loan('verified')
                                    },
                                    onCancel: () => setConfirm({...confirm, open: false})
                                })
                            }}/>
                    </div>
                }

                {/*DISBURSING THE LOAN*/}
                {
                    loanInfo.loan_status === 'verified' && Rights.has_right(user.roles.user_roles, Rights.disburse_loan) &&
                    <div className="d-inline-block mx-1">
                        <Button
                            positive size='mini' content='Disburse Loan' icon="money" labelPosition="left"
                            onClick={() => setShowDisburse(true)}/>
                    </div>
                }

                {/*MAKING LOAN PAYMENT*/}
                {
                    Rights.has_right(user.roles.user_roles, Rights.make_payments)
                    && !['pending', 'rejected', 'accepted', 'verified'].includes(loanInfo.loan_status) &&
                    <div className="d-inline-block mx-1">
                        <Button
                            positive size='mini' content='Make Payment' icon="money bill alternate outline" labelPosition="left"
                            onClick={get_payment_info}/>
                    </div>
                }

                {/*CREATE NEW LOAN*/}
                {
                    Rights.has_right(user.roles.user_roles, Rights.create_loan) &&
                    <div className="d-inline-block mx-1">
                        <Button negative onClick={() => setLoanInfo(initial_loan_info)} icon="add" size='mini'/>
                    </div>
                }

                {/*PRINT LOAN INFO*/}
                {
                    loanInfo.loan_id > 0 &&
                    <div className="d-inline-block mx-1">
                        <Button positive size='mini' icon="info circle" onClick={print_loan_info}/>
                    </div>
                }

                {/*PRINTING LOAN SCHEDULE*/}
                {
                    loanInfo.loan_id !== 0 &&
                    <div className="d-inline-block mx-1">
                        <Button positive size='mini' icon="print" onClick={print_schedule}/>
                    </div>
                }


                {
                    loanInfo.loan_id > 0 && ['pending', 'rejected', 'accepted', 'verified'].includes(loanInfo.loan_status) &&
                    <div className="d-inline-block mx-1">
                        <Button negative size='mini' icon="trash" onClick={() => setShowDelete(true)}/>
                    </div>
                }

                {
                    loanInfo.loan_id > 0 && !['pending', 'rejected', 'accepted', 'verified'].includes(loanInfo.loan_status) &&
                    user.staff.user_email === "pandewilliam100@gmail.com" &&
                    <div className="d-inline-block mx-1">
                        <Button positive size='mini' icon="law" onClick={adjust_loan_info}/>
                    </div>
                }

                {
                    (loanInfo.loan_status === 'active' && loanInfo.payments.summary.principal.balance > 10000000000) &&
                    <div className="d-inline-block mx-1">
                        <Button negative size='mini' icon="window close" content="Bad Debt" onClick={() => setShowBadDebt(true)}/>
                    </div>
                }
            </div>
        </>
    )
}
