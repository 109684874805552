import {LoanInfo} from "../../ShowLoan";
import React from "react";
import Utils, {ConfirmAction, initial_confirm} from "../../../../../utils/Utils";
import {ToastsStore} from "react-toasts";
import {Req} from "../../../../../utils/Req";
import {Loading, MyInput, MyLabel} from "../../../../../utils/Components";
import {Button, Checkbox, Confirm, Form, Table, TextArea} from "semantic-ui-react";
import {useAppSelector} from "../../../../../store/hooks";
import {getStaff} from "../../../../../store/slices/userSlice";

export interface DeductInterest {
    removal_id: number
    amount_removed: string | number
    reason_removed: string
    date_removed: string
    removed_by: string
}

export default function RemoveInterest(params: { loanInfo: LoanInfo, setLoanInfo: (loanInfo: LoanInfo) => void }) {
    const user = useAppSelector(getStaff)
    const [confirm, setConfirm] = React.useState<ConfirmAction>(initial_confirm)
    const [loader, setLoader] = React.useState({message: "", show: false})

    const initial_deduct = {removal_id: 0, removed_by: "", amount_removed: "", date_removed: Utils.today(), reason_removed: "", write_off: false}
    const [deduct, setDeduct] = React.useState(initial_deduct)
    const handle_deduct = (name: string, value: string) => {
        setDeduct({...deduct, [name]: value})
    }

    const deduct_interest = () => {
        const interest = Utils.strip_commas(deduct.amount_removed)
        if (!Utils.is_valid_number(interest) || parseFloat(interest) <= 0) {
            ToastsStore.error("Enter valid amount to be waived off")
        } else {
            setConfirm({
                ...confirm, 'open': true, 'content': `Are you sure you want to proceed with waiving off interest from this loan?`,
                'onConfirm': () => {
                    setConfirm({...confirm, 'open': false})
                    setLoader({message: "Waiving off interest, please wait", show: true})

                    Req.deduct_interest({
                        amount_removed: parseFloat(interest),
                        reason_removed: deduct.reason_removed,
                        loan_id: params.loanInfo.loan_id
                    })
                        .then((response) => {
                            setLoader({message: "", show: false})
                            if (response.data.hasOwnProperty("code")) {
                                if (response.data.code === 1) {
                                    ToastsStore.success("Successfully removed loan interest")
                                    setDeduct(initial_deduct)
                                    params.setLoanInfo(Utils.format_loan_data(response))
                                } else if (response.data.code === 2) {
                                    ToastsStore.error(`Maximum amount of interest ${response.data.amount} can be deducted from the loan`);
                                } else if (response.data.code === 3) {
                                    ToastsStore.error(`At least ${response.data.amount.required} must be paid to write off oan`)
                                }
                            } else {
                                ToastsStore.error("Cannot deduct interest from loan, please retry")
                            }
                        })
                        .catch(() => {
                            setLoader({message: "", show: false})
                            ToastsStore.error("Cannot deduct interest from loan, please retry")
                        })
                },
                'onCancel': () => setConfirm({...confirm, 'open': false})
            })
        }
    }

    return (
        <>
            <Loading show={loader.show} text={loader.message} hide={() => setLoader({...loader, show: false})}/>

            <Confirm open={confirm.open} onCancel={confirm.onCancel} onConfirm={confirm.onConfirm} centered={false} size='mini'
                     cancelButton={confirm.cancelButton} confirmButton={confirm.confirmButton} content={confirm.content}/>

            <div className="row mx-0 h-100">
                <div className="col-12 p-1 col-md-8 h-100">
                    <div className="table_container full_height">
                        <Table celled striped compact size='small' inverted color='grey' selectable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell style={{width: '50px'}} textAlign={'center'}>No.</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '140px'}}>Date Removed</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '150px'}}>Removed By</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '120px'}}>Amount Removed</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '250px'}}>Reason Removed</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {
                                    params.loanInfo.others.removals
                                        .map((info, index) =>
                                            <Table.Row key={info.removal_id}>
                                                <Table.Cell style={{width: '50px'}} textAlign={'center'}>{Utils.row_number(index)}</Table.Cell>
                                                <Table.Cell style={{width: '140px'}}>{Utils.localise_date(info.date_removed)}</Table.Cell>
                                                <Table.Cell style={{width: '150px'}}>{info.removed_by}</Table.Cell>
                                                <Table.Cell style={{width: '120px'}}>
                                                    {Utils.comma_number(info.amount_removed as number)}
                                                </Table.Cell>
                                                <Table.Cell style={{width: '250px'}}>{info.reason_removed}</Table.Cell>
                                            </Table.Row>
                                        )
                                }
                            </Table.Body>
                        </Table>
                    </div>
                </div>

                <div className="col-12 p-1 col-md-4 h-100">
                    <div className="form">
                        <div className='form_content'>
                            <MyInput
                                placeholder="Interest Deducted" name="amount_removed" title={`Interest waived off`}
                                value={Utils.comma_input(deduct.amount_removed)}
                                change={(name, value) => setDeduct({...deduct, [name]: value, write_off: false})}/>

                            <Checkbox
                                checked={deduct.write_off} label="Write off loan" className={"mb-2"}
                                onChange={(_event, data) =>
                                    setDeduct({
                                        ...deduct, write_off: data.checked as boolean,
                                        amount_removed: (data.checked as boolean) ?
                                            (
                                                params.loanInfo.payments.summary.interest.balance +
                                                params.loanInfo.payments.summary.principal.balance +
                                                (params.loanInfo.payments.summary.charges?.balance ?? 0) +
                                                (params.loanInfo.payments.summary.penalties?.balance ?? 0)
                                            ).toString()
                                            : deduct.amount_removed
                                    })
                                }/>

                            <div style={{marginTop: '15px'}}>
                                <MyLabel title="Reason for waiving interest"/>
                                <Form>
                                    <TextArea
                                        placeholder='Enter reason as to why you have waived interest from loan' rows={5} value={deduct.reason_removed}
                                        onChange={((event, data) => handle_deduct('reason_removed', data.value as string))}/>
                                </Form>
                            </div>
                        </div>

                        <div className="form_footer">
                            <div className="row m-0">
                                <div className="col-6 pl-0 pr-1">
                                    <Button
                                        negative onClick={() => setDeduct(initial_deduct)} labelPosition={"left"}
                                        icon='close' content="Clear Data" size='mini' fluid
                                        disabled={
                                            !user.roles.core_roles?.includes("waive_interest")
                                            || params.loanInfo.payments.summary.principal.balance < 1
                                        }/>
                                </div>
                                <div className="col-6 pl-1 pr-0">
                                    <Button
                                        positive onClick={deduct_interest} content={deduct.write_off ? "Write Off" : "Waive Interest"} size='mini' fluid
                                        icon="save" labelPosition={"left"}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
